/* Outer container */
.react-flow {
    background-color: black;
}

/* Inner container */
.react-flow__renderer {}

/* Zoom & pan pane */
.react-flow__zoompane {}

/* Selection pane */
.react-flow__selectionpane {}

/* User selection */
.react-flow__selection {}

/* Edges wrapper */
.react-flow__edges {}

/* Edge element */
.react-flow__edge {}

/* is added when edge is selected */
.react-flow__edge:is(.selected)
/*is added when edge is animated */
.react-flow__edge:is(.animated)

/* Edge element path */
.react-flow__edge-path {}

/* Edge text */
.react-flow__edge-text {}

/* Edge text background */
.react-flow__edge-textbg {}

/* Connection line */
.react-flow__connection {}

/* Connection line path */
.react-flow__connection-path {}

/* Nodes wrapper */
.react-flow__nodes {}

/* Node element */
.react-flow__node {}

/* is added when edge is selected */
.react-flow__node:is(.selected) {}

/* output node */
.react-flow__node-out {}

/* Nodes selection */
.react-flow__nodesselection {}

/* Nodes selection rect */
.react-flow__nodesselection-rect {}

/* Handle component */
.react-flow__handle {
    width: 1rem;
    height: 1rem;
    translate: 0px -50%;
    cursor: pointer !important;
}

/* is added when position='bottom' */
.react-flow__handle-bottom {}

/* is added when position='top' */

.react-flow__handle-top {}

/* is added when position='left' */
.react-flow__handle-left {

    left: 0px;
    transform: translateX(-50%);
}

/* is added when position='right' */
.react-flow__handle-right {
    right: 0px;
    transform: translateX(50%);
}

/* is added when connection line is above a handle */
.react-flow__handle-connectin

/* is added when connection line is above a handle and the connection is valid .react-flow__background */
g {}

.react-flow__handle-valid {}

.react-flow__minimap {}

.react-flow__controls {}